import classNames from 'shared/lib/class-names'

import cls from './About.module.scss'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'

export default function About() {
  return (
    <section className={classNames('flex__col_center', cls.page)} id="about">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <div className={cls.left_block__content}>
            <h2 className={cls.title}>
              С Freedom зарабатывают <span>120 790 жителей</span> Молдовы
            </h2>
          </div>
        </div>
        <div className={cls.center_block}>
          <div className={cls.desc}>
            <p>
              Откройте счет до конца месяца, чтобы получить бесплатные пакеты
              акций и начать зарабатывать пассивный доход!
            </p>
          </div>
        </div>
        <div className={cls.right_block}>
          <SubmitAffirmationModal
            btnContent="открыть инвест-счет"
            btnProps={{ variant: 'variant3' }}
          />
        </div>
      </div>
    </section>
  )
}
